import {inject, IReactComponent} from 'mobx-react';

import {IAppStore} from '../../store';
import {StartState, IStartStateProps} from './Start.state';
import withBranding from '../../components/with-branding';

const mapStoresToProps = (stores: IAppStore, props: IStartStateProps) => ({
    ctrl: stores.startController,
    brandingInfo: props.brandingInfo
});

export default withBranding(inject(mapStoresToProps)(StartState)) as IReactComponent;
