import {action, computed, observable} from 'mobx';
import {WarmTransferRoomStatus} from '@techsee/techsee-common/lib/constants/room.constants';
import {IRoomService} from '@techsee/techsee-client-infra/lib/services/RoomService';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IEventLogService} from '@techsee/techsee-client-infra/lib/services/EventLogService';
import {IBrowserDetectService} from '../../services/BrowserDetectService';
import {IPendingRoomsService, PendingRoomsService} from '../../services/PendingRoomsService';

export interface IStartController {
    readonly validationError: boolean;
    readonly pendingRoomGuid: string;
    readonly roomStatus: string;
    readonly pollingIsExecuted: boolean;

    startPollingRoomStatus(): void;
}

export class StartController implements IStartController {
    @observable private _validationError = false;
    @observable private _roomStatus = WarmTransferRoomStatus.UNKNOWN;
    @observable private _pollingIsExecuted = false;

    private _pendingRoomsService: IPendingRoomsService;

    constructor(
        private _roomService: IRoomService,
        private _browserDetectService: IBrowserDetectService,
        private _browserUtilsService: IBrowserUtilsService,
        private _eventLogService: IEventLogService,
        private _pendingRoomGuid: string
    ) {
        this.setRoomStatus = this.setRoomStatus.bind(this);
        this.onRoomStatusChanged = this.onRoomStatusChanged.bind(this);
        this.startPollingRoomStatus = this.startPollingRoomStatus.bind(this);

        this._pendingRoomsService = new PendingRoomsService(
            this._browserUtilsService,
            this._roomService,
            this._eventLogService,
            _pendingRoomGuid
        );

        this._pendingRoomsService.on('roomStatusChanged', (roomDetails) => this.onRoomStatusChanged(roomDetails));
        this._pendingRoomsService.on('pollingIsExecuted', (pollingIsExecuted: boolean) =>
            this.setPollingExecutionStatus(pollingIsExecuted)
        );
    }

    private readonly onRoomStatusChanged = (roomDetails: {status: WarmTransferRoomStatus | undefined; url: string}) => {
        this.setRoomStatus(roomDetails.status);

        if (roomDetails.status === WarmTransferRoomStatus.READY) {
            this._browserUtilsService.redirect(roomDetails.url);
        }
    };

    @computed
    get validationError(): boolean {
        return this._validationError;
    }

    @computed
    get pendingRoomGuid(): string {
        return this._pendingRoomGuid;
    }

    @computed
    get roomStatus(): WarmTransferRoomStatus {
        return this._roomStatus;
    }

    @computed
    get pollingIsExecuted(): boolean {
        return this._pollingIsExecuted;
    }

    @action
    private readonly setRoomStatus = (state: WarmTransferRoomStatus = WarmTransferRoomStatus.UNKNOWN) => {
        this._roomStatus = state;
    };

    @action
    private setPollingExecutionStatus(pollingExecutionStatus: boolean) {
        this._pollingIsExecuted = pollingExecutionStatus;
    }

    async startPollingRoomStatus(): Promise<void> {
        this._setValidationError(false);

        try {
            return await this._pendingRoomsService.pollRoomStatus();
        } catch (error) {
            this._setValidationError(true);

            return Promise.resolve();
        }
    }

    @action
    private _setValidationError(state: boolean) {
        this._validationError = state;
    }
}
