import React from 'react';
import {Navigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import classNames from 'classnames';

import {Loader} from '../../components/loader';
import {IStartController} from './Start.controller';

import {WarmTransferRoomStatus} from '@techsee/techsee-common/lib/constants/room.constants';
import {IBrandingInfo} from '@techsee/techsee-common/lib/constants/desktop-sharing.constants';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';

import './styles.scss';

export interface IStartStateProps {
    ctrl: IStartController;
    brandingInfo: IBrandingInfo;
}

@observer
export class StartState extends React.Component<IStartStateProps> {
    get logoUrl(): string {
        return this.props.brandingInfo.logo;
    }

    render(): React.ReactNode {
        const {startPollingRoomStatus, pendingRoomGuid, roomStatus, pollingIsExecuted} = this.props.ctrl;
        const isExpired = roomStatus === WarmTransferRoomStatus.EXPIRED;
        const isReady = roomStatus === WarmTransferRoomStatus.READY;

        if (isExpired) {
            return <Navigate to='/expired' />;
        }

        const {brandingInfo} = this.props;
        const textLabelsComponent = (
            <div className='labels-container'>
                <SimpleLabel className='primary-label'>Please wait for your host to start the meeting</SimpleLabel>
            </div>
        );

        const loader = (
            <div className='use-common-styles centered-spinner'>
                {brandingInfo.loader ? <img src={brandingInfo.loader} alt='loader' /> : <Loader />}
            </div>
        );

        return (
            <div className={classNames('page', brandingInfo.backgroundColor)}>
                <div className='page__logo'>
                    <div className='logo_container'>
                        <img src={this.logoUrl} alt='' />
                    </div>
                </div>

                {pendingRoomGuid && (
                    <div className='page__content'>
                        {textLabelsComponent}
                        {pollingIsExecuted && loader}
                        {!isExpired && !isReady && !pollingIsExecuted && (
                            <span className='retry-link' onClick={startPollingRoomStatus}>
                                Retry
                            </span>
                        )}
                    </div>
                )}

                <div className='powered-by'>Powered by TechSee</div>
            </div>
        );
    }
}
