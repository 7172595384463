require('es6-promise').polyfill();

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {AppComponent} from './app';

import 'normalize.css/normalize.css';
import '../config/techsee/error-reporting';

import {AppStore, IAppStore} from './store';

//Configures MobX to allow changing state from actions only.
configure({
    enforceActions: 'observed'
});

// @ts-ignore
const environmentDetect = await TsEnvironmentDetect.create();

const appStores: IAppStore = new AppStore(environmentDetect);

const store: IAppStore = {
    startController: appStores.startController,
    expiredController: appStores.expiredController,
    withBrandingController: appStores.withBrandingController,
    subDomain: appStores.subDomain
};

const Application = (
    <BrowserRouter>
        <Provider {...store}>
            <AppComponent />
        </Provider>
    </BrowserRouter>
);

ReactDOM.render(Application, document.getElementById('app-root-element'));
