import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

export interface IBrowserDetectService {
    isSupported: boolean;
}

export class BrowserDetectService implements IBrowserDetectService {
    constructor(private _tsEnvironmentDetect: ITsEnvironmentDetect) {}

    get isSupported(): boolean {
        return (
            !this._tsEnvironmentDetect.isAndroid() &&
            !this._tsEnvironmentDetect.isIOS() &&
            ((this._tsEnvironmentDetect.isChrome() && this._tsEnvironmentDetect.majorVersion() >= 72) ||
                (this._tsEnvironmentDetect.isFF() && this._tsEnvironmentDetect.majorVersion() >= 52) ||
                (this._tsEnvironmentDetect.isSafari() && this._tsEnvironmentDetect.majorVersion() >= 13) ||
                (this._tsEnvironmentDetect.isEdge() && this._tsEnvironmentDetect.majorVersion() >= 79))
        );
    }
}
