import * as React from 'react';
import {
    Spinner,
    SpinnerTypeOptions,
    SpinnerSizes,
    SpinnerBackgroundColors,
    SpinnerColors
} from '@techsee/techsee-ui-common/lib/spinner';

const Loader: React.FunctionComponent = () => (
    <Spinner
        className={'centered-spinner'}
        spinnerType={SpinnerTypeOptions.DASH}
        sizeName={SpinnerSizes.XSmall}
        backgroundColorName={SpinnerBackgroundColors.Transparent}
        colorName={SpinnerColors.VeryDarkGray}
    />
);

export default Loader;
